import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import "../css/slider.css";
import Logo from "../../images/oscarWhite.png";
import Map from "../../images/acconMap.png";

const Footer = () => {
  return (
    <div tw="bg-green-600 text-white p-8">
      <div
        className="Grid___StyledDiv-sc-10u6u2m-1"
        tw="flex leading-snug flex-row flex-wrap justify-between mx-auto"
      >
        <div tw="mt-4 w-full sm:w-1/2 lgxl:w-1/3 ">
          <div tw="text-4xl">Oscar Products Ltd</div>
          <div tw="mt-2 sm:pr-10 font-light">
            1 Inca Business Park
            <br />
            Melford Road
            <br />
            Acton
            <br />
            Sudbury
            <br />
            Suffolk
            <br />
            CO10 0BB
          </div>
        </div>
        <div tw="mt-4 w-full sm:w-1/2 lgxl:w-1/3 ">
          <div tw="text-4xl">Contact</div>
          <div tw="mt-2 sm:pr-10 font-light">
            E-mail:{" "}
            <a
              href="mailto:ashish@oscarproducts.com"
              tw="no-underline text-white hover:underline ml-3"
            >
              ashish@oscarproducts.com
            </a>
            <br />
            <a
              href="mailto:jackie@oscarproducts.com"
              tw="no-underline text-white hover:underline ml-16"
            >
              jackie@oscarproducts.com
            </a>
            <br />
            <a
              href="mailto:fraser@oscarproducts.com"
              tw="no-underline text-white hover:underline ml-16"
            >
              fraser@oscarproducts.com
            </a>
            <br />
            <br />
            Tel.:{" "}
            <a
              href="tel:01787 313840"
              tw="no-underline text-white hover:underline ml-8"
            >
              01787 313840
            </a>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.co.uk/maps/place/Oscar+Products+Ltd/@52.0778404,0.7460641,17z/data=!3m1!4b1!4m5!3m4!1s0x47d854c20273da67:0xaa64b6cacee2eb48!8m2!3d52.0778404!4d0.7482528?hl=en"
              tw="no-underline text-white hover:underline align-middle"
            >
              <i className="material-icons" tw="text-base">
                place
              </i>
              View Map
            </a>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.co.uk/maps/place/Oscar+Products+Ltd/@52.0778404,0.7460641,17z/data=!3m1!4b1!4m5!3m4!1s0x47d854c20273da67:0xaa64b6cacee2eb48!8m2!3d52.0778404!4d0.7482528?hl=en"
            >
              <img src={Map} alt="Map" tw="w-48" />
            </a>
          </div>
        </div>
        <div tw="mt-4 w-full sm:w-1/2 lgxl:w-1/3 ">
          <img src={Logo} alt="Logo" />
          Other offerings:
          <br />
          <br />
          <a
            href="https://www.oscarproducts.com"
            target="_blank"
            rel="noreferrer"
            tw="no-underline hover:underline text-white"
          >
            www.oscarproducts.com
          </a>
          <br />
          <a
            href="https://www.steelcontainers.eu"
            target="_blank"
            rel="noreferrer"
            tw="no-underline hover:underline text-white"
          >
            www.steelcontainers.eu
          </a>
          <br />
          <a
            href="https://www.dustbinliner.co.uk"
            target="_blank"
            rel="noreferrer"
            tw="no-underline hover:underline text-white"
          >
            www.dustbinliner.co.uk
          </a>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Footer;
