import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Image1 from "../../images/new0.jpg";
import Image2 from "../../images/new1.jpg";
import Image3 from "../../images/new2.JPG";
import Image4 from "../../images/new3.JPG";
import Image5 from "../../images/new4.JPG";
import Image6 from "../../images/new5.jpg";
import Image7 from "../../images/new6.png";
import "../css/slider.css";

const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-81p sm:h-40width lgxl:h-72 w-full bg-contain bg-no-repeat bg-center`,
]);

const Grid = () => {
  const content = [
    {
      id: 1,
      imageSrc: Image1,
    },
    {
      id: 2,
      imageSrc: Image2,
    },
    {
      id: 3,
      imageSrc: Image3,
    },
    {
      id: 4,
      imageSrc: Image4,
    },
    {
      id: 5,
      imageSrc: Image5,
    },
    {
      id: 6,
      imageSrc: Image6,
    },
    {
      id: 7,
      imageSrc: Image7,
    },
  ];

  return (
    <div tw="relative mx-auto py-8 px-8">
      <div tw="flex items-center flex-row flex-wrap justify-center">
        {content.map((image, index) => (
          <div tw="mt-4 w-full sm:w-1/2 lgxl:w-1/4" key={index}>
            <div tw="mx-2">
              <div tw="bg-cover bg-center rounded overflow-hidden shadow-2xl bg-white">
                <CardImage imageSrc={image.imageSrc} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
