import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import tick from "../../images/spellcheck.svg";
import price from "../../images/shopping_cart.svg";
import location from "../../images/place.svg";
import truck from "../../images/alarm.svg";
import sizes from "../../images/sizes.svg";
import phone from "../../images/phone_white.svg";
// import colors from "../../images/pallette.svg";
import "../css/slider.css";

const Grid = () => {
  return (
    <div tw="relative mx-auto py-4">
      <div tw="text-center tracking-wide text-5xl text-green-900 font-semibold px-8 py-8">
        High Quality Weed Control Fabric | Ground Covers | Landscaping Fabric |
        Suitable for Domestic and Commercial Use
      </div>
      <div tw="font-normal tracking-wide mb-6 mx-6 sm:mx-20 md:mx-40 lgxl:mx-40 px-8">
        We supply high quality weed control fabric/ground covers made out of
        regenerated fibers to effectively stop weed growth and negate the need
        of harmful chemicals. We keep stock of various sizes of weed
        control/ground cover fabric all year around which can be delivered
        swiftly on request.
        <br />
        <br />
        Oscar Products Ltd has been serving business' throughout the UK for over
        30 years. Our focus has always been to be a reliable business partner
        that offers intelligent solutions.
        <br />
        <div tw="mt-4">
          Oscar’s concept of service is based on consulting clients in all
          aspects,during and after implementation of a project. Our project
          managers ensure professional work, in order to ensure an optimized
          solution for your industry.
        </div>
        <div tw="mt-4">
          We strongly believe that by combining growth with
          environmental-protection and social-responsibility, we shall achieve a
          real sustainable way of doing business.
        </div>
      </div>
      <div tw="flex items-center flex-row flex-wrap justify-center px-24">
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={tick} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            CERTIFIED PRODUCTS
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={sizes} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            SIZE OPTIONS
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={price} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            COMPETITIVE PRICING
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={truck} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            ON-TIME DELIVERIES
          </span>
        </figure>
        <figure tw="mt-4 w-full sm:w-1/2 lgxl:w-1/5 justify-center mx-auto text-center">
          <img src={location} alt="Checkmark" tw="h-28 mx-auto" />
          <br />
          <span tw="mx-auto text-center mt-12 text-gray-600 font-semibold">
            UK WIDE COVERAGE
          </span>
        </figure>
      </div>
      <div tw="py-4 bg-green-600 w-full mx-0 text-center">
        <div tw="mx-auto text-white font-bold text-3xl">
          Contact Our Team for a Quote
        </div>
        <br />
        <br />
        <div tw="mx-auto text-white font-medium text-lg">
          We can offer landscaping fabric/weed control fabric/ground cover as
          per your requirements, please contact our team.
          <br />
        </div>
        <br />
        <div tw="mx-auto text-white font-bold text-xl">
          <br />
          <a
            href="tel:01787 313840"
            tw="no-underline hover:underline text-white"
          >
            <img src={phone} alt="Checkmark" tw="h-4 items-center mx-auto" />{" "}
            01787 313840
          </a>
          <br />
          <br />
          <a
            href="mailto:ashish@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            ashish@oscarproducts.com
          </a>
          <br />
          <a
            href="mailto:jackie@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            jackie@oscarproducts.com
          </a>
          <br />
          <a
            href="mailto:fraser@oscarproducts.com"
            tw="no-underline hover:underline text-white"
          >
            fraser@oscarproducts.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Grid;
