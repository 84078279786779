import React from "react";
import Nav from "./layouts/Nav";
import Carousel from "./layouts/Carousel";
import Grid from "./layouts/Grid";
import Footer from "./layouts/Footer";
import TextContent from "./layouts/TextContent";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

const Home = () => {
  return (
    <div tw="w-full overflow-x-hidden">
      <Nav />
      <Carousel />
      <TextContent />
      <Grid />
      <Footer />
    </div>
  );
};

export default Home;
