import React from "react";
import logo from "../../images/oscar.png";
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import linkedin from "../../images/linkedin.svg";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

const Nav = () => {
  return (
    <div>
      <div tw="p-1 h-auto bg-white text-gray-900 z-40 top-0">
        <div tw="flex flex-col lg:flex-row justify-between lg:items-center mx-auto">
          <span tw="flex flex-row align-baseline align-baseline items-center lg:w-3/12 mx-auto lg:mx-0">
            <span tw="flex items-center mx-1">
              <div tw="flex flex-row hidden md:flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="rgba(75, 85, 99)"
                  tw="h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                  />
                </svg>
                <a
                  tw="text-sm font-medium align-bottom mx-1 no-underline text-gray-700 mb-1 mt-1 hover:underline"
                  href="mailto:ashish@oscarproducts.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  ashish@oscarproducts.com
                </a>
                |
                <a
                  tw="text-sm font-medium align-baseline mx-1 no-underline text-gray-700 mb-1 mt-1 hover:underline"
                  href="mailto:jackie@oscarproducts.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  jackie@oscarproducts.com
                </a>
              </div>
            </span>
          </span>
          <img src={logo} alt="Logo" tw="w-80 mx-auto lg:mx-0" />
          <div tw="mx-auto lg:mx-0 lg:flex my-6 hidden md:flex">
            <span tw="flex items-center mx-1 ml-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="rgba(75, 85, 99)"
                tw="h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                />
              </svg>
              <a
                tw="text-sm font-medium align-baseline mx-1 no-underline text-gray-700 mb-1 mt-1 hover:underline mr-2"
                href="mailto:fraser@oscarproducts.com"
                target="_blank"
                rel="noreferrer"
              >
                fraser@oscarproducts.com
              </a>
              {"|"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="rgba(75, 85, 99)"
                tw="h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <a
                tw="text-base font-medium align-baseline mx-1 no-underline text-gray-700 ml-2 hover:underline"
                href="tel:01787 313840"
              >
                01787 313840
              </a>
            </span>
          </div>
        </div>
      </div>
      <div tw="p-2 h-auto bg-green-600 text-gray-900 z-40 top-0 mx-auto flex">
        <div tw="items-center content-center mx-auto flex">
          <a
            href="https://www.facebook.com/landscapingfabrics.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Facebook Logo" tw="h-6 mx-3" />
          </a>
          <a
            href="https://twitter.com/LANDSCAPINGFAB"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="Twitter Logo" tw="h-6 mx-3" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/landscapingfabrics-co-uk"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="LinkedIn Logo" tw="h-6 mx-3" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;

// phone numbers:
// 01787313840
